// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"_s9Ie_l4wE3yubuUQUxy1"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
    dsn: 'https://56576675e8594a969e7d4c41b5d03eda@o1211948.ingest.sentry.io/4505589051883520',

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    tracesSampler: (samplingContext) => {
        if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
            return 0
        }
        return 0.01
    },
})
